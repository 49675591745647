* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color:unset;
  text-decoration: none;
}

.gradient__bg {
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 3% 25%, #002853 0%, #823E82 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 3% 25%, #002853 0%, #823E82 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 3% 25%, #002853 0%, #823E82 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 3% 25%, #002853 0%, #823E82 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, #002853 0%, #823E82 100%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  padding: 4rem 6rem;
}

.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  
  .section__margin {
    padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .section__margin {
    padding: 4rem 2rem;
  }
}
