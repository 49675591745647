.gpt3__blog-container_article {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-footer);
}

.gpt3__blog-container_article-image {
  width: 100%;
  height: 100%;
  background: var(--color-footer);
}

.gpt3__blog-container_article-image img {
  width: 100%;
  height: 100%;
}

.gpt3__blog-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 100%;
}

.gpt3__blog-container_article-content p {
  color: #FFF;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 11.649px;
  line-height: 34.947px; /* 300% */
}

.gpt3__blog-container_article-content h3 {
  font-family: var(--font-family);
  font-size: 25.11px;
  line-height: 30.287px; /* 120.619% */
  color: #FFF;
  margin: 5rem;
  margin-left: 0;
  margin-top: 0;
  cursor: pointer;
}

.gpt3__blog-container_article-content p:last-child {
  color: #FFF;
  font-family: var(--font-family);
  font-size: 11.649px;
  line-height: 34.947px; /* 300% */
  cursor: pointer;
  }

  @media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h3 {
      font-size: 18px;
      line-height: 25px;
    }
  }