.gpt3__possibility {
  display: flex;
  flex-direction: row;

}

.gpt3__possibility-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem;
}

.gpt3__possibility-image img {
  width: 100%;
  height: 100%;
}

.gpt3__possibility-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.gpt3__possibility-content h4 {
  font-family: var(--font-family);
  line-height: 30px; /* 187.5% */
  font-size: 16px;
  font-weight: 500;
  color: #71E5FF;
}

.gpt3__possibility-content h4:last-child {
  font-family: var(--font-family);
  line-height: 30px; /* 187.5% */
  font-size: 16px;
  font-weight: 500;
  color: #FF8A71;
}

.gpt3__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px; /* 132.353% */
  margin-bottom: 1rem;
}

.gpt3__possibility-content p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 40px; /* 187.5% */
  color: var(--color-text);

  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .gpt3__possibility {
    flex-direction: column;
  }

  .gpt3__possibility-image {
    margin: 1rem 0;
  }

  .gpt3__possibility-content {
    margin-top: 2rem
  }
}